import styled from 'styled-components';

const Styled = {};

Styled.List = styled.ul`
  padding: 0;
  margin: 0;
`;

export default Styled;
