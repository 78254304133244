import styled from 'styled-components';

import loadingSpinner from '@/img/download-spinner.gif';

const Styled = {};

Styled.SearchPage = styled.div`
  display: block;

  mark {
    background-color: ${props => props.theme.color.highlight};
  }
`;

Styled.Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  &::before {
    content: "";
    display: block;
    width: 100px;
    height: 100px;
    background: url(${loadingSpinner}) center center no-repeat;
  }
`;

export default Styled;
