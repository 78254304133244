import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import logo from '@/img/logo.png';
import SC from './style';

import PublishDate from '@/components/PublishDate';

const PressRoll = ({ post }) => {
  const { locale: language } = useIntl();
  const localizedContent = _.get(post, 'frontmatter.languages.content') || {};
  const thumbnail = _.get(post, 'frontmatter.thumbnail.childImageSharp.fluid.src') || logo;
  const briefDescription = (localizedContent.brief_description || localizedContent.description || '').substring(0, 280);

  return (
    <SC.ListItem>
      <SC.LinkContainer
        className="has-text-primary"
        to={`/${language}${post.fields.slug}`}>
        {thumbnail &&
          <SC.ImageContainer>
            <img src={thumbnail} alt="" />
          </SC.ImageContainer>
        }
        <SC.ContentContainer>
          {localizedContent.highlighted_title
            ? (
              <SC.PostTitle
                dangerouslySetInnerHTML={{ __html: localizedContent.highlighted_title }}
              />
            )
            : <SC.PostTitle>{localizedContent.title}</SC.PostTitle>
          }
          {localizedContent.highlighted_brief_description
            ? (
              <p
                className="is-size-6"
                dangerouslySetInnerHTML={{ __html: localizedContent.highlighted_brief_description }}
              />
            )
            : <p className="is-size-6">{briefDescription}</p>
          }
          <PublishDate date={post.frontmatter.date} separator=": " />
        </SC.ContentContainer>
      </SC.LinkContainer>
    </SC.ListItem>
  );
};

PressRoll.propTypes = {
  post: PropTypes.object.isRequired,
};

export default PressRoll;
