import React from 'react';
import PropTypes from 'prop-types';

import App from '@/components/App';
import PageMainContent from '@/components/PageMainContent';
import SearchPage from '@/components/SearchPage';

const PressSearchPage = ({ pageContext }) => (
  <App language={pageContext.language} defaultLanguage={pageContext.defaultLanguage}>
    <PageMainContent>
      <SearchPage />
    </PageMainContent>
  </App>
);

PressSearchPage.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string,
    defaultLanguage: PropTypes.string,
  }),
};

export default PressSearchPage;
