import React from 'react';
import PropTypes from 'prop-types';
import SC from './style';

import PressRoll from '@/components/PressRoll';

const PressRollList = ({ posts }) => {
  return (
    <SC.List>
      {posts.map(({ node: post }) => <PressRoll key={post.id || post.fields.slug} post={post} />)}
    </SC.List>
  );
};

PressRollList.propTypes = {
  posts: PropTypes.array,
};

export default PressRollList;
