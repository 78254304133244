import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import FormattedDate from '@/components/FormattedDate';

export const StyledPublishDate = styled.p`
  font-weight: bold;
`;

const PublishDate = ({ date, separator }) => (
  <StyledPublishDate>
    <FormattedMessage id="press.publishDate" />
    {separator}
    <time dateTime={date}>
      <FormattedDate
        value={date}
        year="numeric"
        month="short"
        day="numeric"
      />
    </time>
  </StyledPublishDate>
);

PublishDate.propTypes = {
  date: PropTypes.string.isRequired,
  separator: PropTypes.any,
};

PublishDate.defaultProps = {
  separator: <br />,
};

export default PublishDate;
