import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Styled = {};

Styled.ListItem = styled.li`
  list-style-type: none;
  width: 100%;
  border-top: 1px solid rgba(161, 175, 192, 0.3);
  position: relative;
  padding: 0;
`;

Styled.LinkContainer = styled(props => <Link {...props} />)`
  display: flex;
  padding: 20px;

  &:hover h3,
  &:hover p {
    color: ${props => props.theme.color.link};
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

Styled.ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 170px;

  img {
    display: block;
    width: auto;
    margin: 0 auto;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

Styled.ContentContainer = styled.div`
  flex: 1;
  padding: 0 20px;
`;

Styled.PostTitle = styled.h2`
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: ${props => props.theme.font.size3};

  @media ${props => props.theme.device.below.tablet} {
    font-size: ${props => props.theme.font.size4};
  }
`;

export default Styled;
