import React, { useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import {
  SEARCH_FILTER_KEYWORD,
  SEARCH_REQUESTED,
  SEARCH_SUCCEEDED,
} from '@/state/searchFilter/action';

import Head from '@/components/Head';
import PressRollList from '@/components/PressRollList';
import PageHeader from '@/components/PageHeader';
import SearchForm from '@/components/SearchForm';
import Api from '@/service/search';
import getLocationSearch from '@/utils/getLocationSearch';
import trackPage from '@aa/trackPage';
import SAMMap from '@aa/SAMMap';

import convertResultToPosts from './convertResultToPosts';
import SC from './style';

const SearchPage = () => {
  const intl = useIntl();
  const { locale: language }  = intl;

  const dispatch = useDispatch();
  const {
    keyword: locationKeyword = '',
    from: locationFromDate = '',
    to: locationToDate = '',
  } = getLocationSearch();

  const [fromDate, setFromDate] = useState(locationFromDate);
  const [toDate, setToDate] = useState(locationToDate);
  const [lastLocationKeyword, setLastLocationKeyword] = useState(locationKeyword);

  const response = useSelector(({ searchFilter: { response } }) => response, shallowEqual);
  const keyword = useSelector(({ searchFilter: { keyword } }) => keyword);
  const isSearchRequested = useSelector(({ searchFilter: { isSearchRequested } }) => isSearchRequested);

  if (
    !keyword && locationKeyword
    || lastLocationKeyword !== locationKeyword
  ) {
    setLastLocationKeyword(locationKeyword);
    dispatch({ type: SEARCH_FILTER_KEYWORD, payload: locationKeyword });
    dispatch({ type: SEARCH_REQUESTED });
  }

  const onFromDateChange = (value) => {
    setFromDate(value);
  };
  const onToDateChange = (value) => {
    setToDate(value);
  };

  useMemo(() => {
    async function fetchData() {
      if (isSearchRequested) {
        const response = await Api.fetchSearch(keyword);
        const posts = convertResultToPosts({ response, language, fromDate, toDate });

        if (keyword !== '' && posts.length >= 1) {
          trackPage({
            pageId: `${SAMMap[language].search.pageId}${keyword}_${posts.length}`,
            ...SAMMap[language].common
          });
        } else {
          trackPage({
            pageId: SAMMap[language].search.pageId,
            ...SAMMap[language].common
          });
        }

        dispatch({ type: SEARCH_SUCCEEDED, payload: response });
      }
    }
    fetchData();
  }, [isSearchRequested]);
  const posts = convertResultToPosts({ response, language, fromDate, toDate });

  return (
    <SC.SearchPage>
      <Head title={`${intl.formatMessage({ id: 'press.search.title' })} - ${keyword}`} />
      <PageHeader
        title={intl.formatMessage({ id: 'press.search.title' })}
      />
      <SearchForm
        onFromDateChange={onFromDateChange}
        onToDateChange={onToDateChange}
      />
      { isSearchRequested ? (
        <SC.Loading />
      ) : (
        <PressRollList posts={posts} />
      )}
    </SC.SearchPage>
  );
};

export default SearchPage;
