const convertResultToPosts = ({
  response: {
    hits: {
      hits = []
    } = {}
  } = {},
  language: targetLanguage,
  fromDate,
  toDate,
}) => hits.map(({
  _id: resultId,
  _source: {
    title,
    brief_description,
    date,
    thumbnail,
    language,
  },
  highlight: {
    title: highlighted_titles = [],
    brief_description: highlighted_brief_descriptions = [],
  } = {},
}) => {
  const [, slug] = resultId.split('/');
  const nowTime = new Date(date).getTime();
  const fromTime = new Date(fromDate).getTime() || -Infinity;
  const toTime = (new Date(toDate).getTime() + 86400) || Infinity; // Add one day

  const filterResponse = () => (
    targetLanguage === language
    && fromTime <= nowTime
    && toTime >= nowTime
  );

  return filterResponse() ? {
    node: {
      fields: {
        slug: `/press/${slug}`,
      },
      frontmatter: {
        date,
        languages: {
          content: {
            title,
            brief_description,
            highlighted_title: highlighted_titles.join(),
            highlighted_brief_description: highlighted_brief_descriptions.join(),
            thumbnail,
          },
        },
        slug,
      }
    }
  } : null;
}).filter(item => item !== null);

export default convertResultToPosts;
