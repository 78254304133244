import styled from 'styled-components';

const Styled = {};

Styled.SearchForm = styled.form`
  position: relative;
  padding: 20px;
  max-width: 375px;
`;

Styled.Fieldset = styled.fieldset`
  display: block;
`;

Styled.FieldList = styled.ul`
  display: block;
`;

Styled.FieldItem = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  max-with: 375px;
  margin: 0.5rem 0;
`;

Styled.Label = styled.label`
  display: block;
  flex: 1;
  max-width: 100px;
  font-size: ${props => props.theme.font.size5};
`;

Styled.Legend = styled.legend`
  display: block;
  font-weight: bold;
  padding-top: 0.5rem;
  font-size: ${props => props.theme.font.size5};
`;

Styled.Input = styled.input`
  display: block;
  flex: 1;
  max-width: 300px;
  padding: 4px 10px;
  font-size: ${props => props.theme.font.size5};
  border: 0;
  border-radius: 12px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
`;

Styled.FormButtonContainer = styled.div`
  display: flex;
  padding: 10px 0;
`;

Styled.FormButton = styled.button`
  display: inline-block;
  position: relative;
  height: 27px;
  min-width: 90px;
  padding: 0;
  margin: 0;
  border: 0;

  font-size: ${props => props.theme.font.size6};
  line-height: 26px;
  text-align: center;
  border-radius: 20px;
  background: #eef6fa linear-gradient(to right, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.28);
  cursor: pointer;
`;

Styled.FormSubmitButton = styled(Styled.FormButton)`
  color: #fff;
  text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.2);
  transition: background 0.2s linear 0s;
  background: #61941b linear-gradient(to bottom, rgba(255, 255, 255, 0.56) 0px, rgba(255, 255, 255, 0) 100%);

  &:hover,
  &:focus,
  &:active {
    background-color: #8abf3f;
  }
`;

export default Styled;
