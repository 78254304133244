import axios from 'axios';

const API_AES_ENDPOINT = '//search-press-site-latest-7xs5ewhod56z63bhp7i7dsw2wq.ap-northeast-1.es.amazonaws.com/press-release/_search';

const Api = {
  fetchSearch: (keyword, size = 50) => {
    return axios(API_AES_ENDPOINT, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        size,
        query: {
          multi_match: {
            fields: ['title^4', 'brief_description^2', 'description'],
            query: keyword
          }
        },
        highlight: {
          fields: {
            title: {},
            brief_description: {},
          },
          pre_tags: '<mark>',
          post_tags: '</mark>',
        }
      })
    }).then(response => response.data);
  },
};

export default Api;